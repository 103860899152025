.main-layout {
  height: 100%;
}

.ant-layout-content {
  background-color: white;
}

.ant-layout-header {
  background-color: white;
  height: auto;
  padding: 0 0 0 0;
}

.layout-container.ant-layout {
  min-height: initial;
}

.layout-sider {
  background: #ffffff;
}

.layout-content {
  width: 100%;
}

.narrow-layout {
  max-width: 1024px;
  margin: auto;
}

.narrow-layout.ant-card {
  max-width: 1024px;
  margin: auto;
}

.ant-table-tbody>tr.ant-table-row-selected td {
  background: #faad14 !important;
}

.ant-table td { white-space: nowrap }
