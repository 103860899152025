.login-form {
  border-radius: 4px;
  background: #fff;
  padding: 32px 32px 0;
  min-width: 300px;
  box-shadow: 0 0 50px 0 rgba(0,0,0,0.2);
}

.login-form > .title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 20px;
}

.login-form > .logo {
  display: block;
  margin: 0 auto 12px;
}

.language-switcher-container {
  float: right;
}