.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  background-color: white;
}

.ant-layout-header {
  height: 100px !important;
}

.user-panel > .panelelement {
  margin: 6px;
  border: 0;
}

.corpHeader {;
  height: 90px;
}

.corpName {
  width: 20vw;
  font-size: 0.9vw;
  font-weight: 900;
  height: 26px;
}

.corpAddr {
  font-size: 0.8vw;
  font-weight: 400;
  height: 22px;
}

.corpPhone {
  font-size: 0.8vw;
  font-weight: 400;
}

.rightMenu {
  justify-content: flex-end;
  border-right: 0;
}

.ant-menu-horizontal {
  margin-top: 10px;
  border-bottom: 0;
}
