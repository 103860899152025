.language-switcher .ant-select-selection {
  border: none;
}

.language-switcher.-header .ant-select-selection {
  background: none;
  border: none;
  color: #CCCCCC;
}

.language-switcher.-header .ant-select-arrow {
  color: #CCCCCC;
}
