.fgHeader {
    opacity: 1.0;
    font-family: Poppins, sans-serif;
    font-size: 100px;
    font-weight: bold;
    color: white;
    position: absolute;
    left: 30px;
    top: 70px;
}

.background {
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(./hero.png);
  background-size: cover;
  height: 50vh;
}

.fgText {
  opacity: 1.0;
  font-size: medium;
  font-family: Poppins, sans-serif;
  font-weight: normal;
}

.fgHeader2 {
  opacity: 1.0;
  font-size: x-large;
  font-family: Poppins, sans-serif;
  font-weight: bold;
}

.contact {
  background-color: #1890ff;
}

.contact .ant-collapse-header {

  color: white !important;
}